import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #1a202c;
  color: white;
`;

const Logo = styled.h1`
  font-size: 24px;
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 20px;

  a {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <Logo>Sukram Nenojros</Logo>
      <NavLinks>
        <Link to="/">Bio</Link>
        <Link to="/skills">Skills</Link>
        <Link to="/portfolio">Portfolio</Link>
        <Link to="/blog">Blog</Link>
        <Link to="/cv">Download CV</Link>
      </NavLinks>
    </HeaderContainer>
  );
};

export default Header;
