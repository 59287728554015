import React from "react";

const Bio = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white p-10">
      <h1 className="text-4xl font-bold mb-4">About Me</h1>
      <p className="text-lg">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
      </p>
    </div>
  );
};

export default Bio;
