import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Bio from "./pages/Bio";
import Skills from "./pages/Skills";
import Portfolio from "./pages/Portfolio";
import Blog from "./pages/Blog";
import CV from "./pages/CV";

const App = () => {
  return (
    <div className="dark bg-gray-900 text-white p-10">
      <Header />
      <Routes>
        <Route path="/" element={<Bio />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/cv" element={<CV />} />
        <Route path="*" element={<div>404 Not Found</div>} />
      </Routes>
    </div>
  );
};

export default App;
